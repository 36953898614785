import Swiper from 'swiper';
import {Autoplay} from 'swiper/modules';

Swiper.use([Autoplay])
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

document.addEventListener("DOMContentLoaded", () => {
    const targets = document.querySelectorAll('.lyb-timeline-icon');
    const viewportHeight = window.innerHeight;

    const timelineAnimations = () => {
        targets.forEach(target => {
            const rect = target.getBoundingClientRect();
            if (rect.top < viewportHeight / 2) {
                target.classList.add('past-50');
            } else {
                target.classList.remove('past-50');
                target.style.webkitAnimationPlayState = "running"
            }
        });
    };

    document.querySelectorAll('.phone-slider').forEach(swiperElement => {
        new Swiper(swiperElement, {
            loop: true,
            slidesPerView: 1,
            autoplay: {
                delay: 2000,
            },
        });
    });
    document.querySelectorAll('.story-slider-large').forEach(swiperElement => {
        const slidesCount = swiperElement.dataset.slides || 1;

        new Swiper(swiperElement, {
            spaceBetween: 20,
            speed: 1000,
            direction: 'horizontal',
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true
            },
            loop: true,
            slidesPerView: 2,
            centeredSlides: true,
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                1600: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            }
        });
    });


    // Initial check in case elements are already past 50% on load
    timelineAnimations();

    // Attach the scroll event listener
    window.addEventListener('scroll', timelineAnimations);
    document.querySelectorAll('.brand-swiper').forEach(swiperElement => {
        const slidesCount = swiperElement.dataset.slides || 1;

        new Swiper(swiperElement, {
            spaceBetween: 40,
            speed: 2500,
            direction: 'horizontal',
            autoplay: {
                delay: 0,
            },
            loop: true,
            freeMode: true,
            slidesPerView: 3,
            breakpoints: {
                640: {
                    slidesPerView: slidesCount > 10 ? 3 : slidesCount / 2,
                },
                1024: {
                    slidesPerView: slidesCount > 20 ? 10 : slidesCount / 2,
                },
            }
        });
    });
})


new Swiper('.brand-swiper-small', {
    loop: true,
    centeredSlides: true,
    autoplay: {
        delay: 1500,
        disableOnInteraction: true,
        pauseOnMouseEnter: true
    },
    breakpoints: {
        1: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        984: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
    },
    spaceBetween: 30,
});

